import React from 'react';
import Layout from '../components/Layout.js';
import LoginForm from '../components/forms/LoginForm.js';

const LoginPage = () => {
  return (
    <Layout title={{ black: 'Please', blue: 'Login' }}>
      <LoginForm />
    </Layout>
  );
};

export default LoginPage;
