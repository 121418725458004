import React from 'react';
import { batch, useDispatch } from 'react-redux';
import FormButton from './inputs/FormButton.js';
import UserInput from './inputs/UserInput.js';
import { clearErrors, setErrors } from '../../stores/Errors/ErrorsActions.js';
import { doLogin, setEmail, setPassword } from '../../stores/Login/LoginActions.js';
import { useLogin } from '../../stores/Login/LoginSelectors.js';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { status, email, password } = useLogin();

  const onEmailChange = value => {
    batch(() => {
      dispatch(setEmail(value));
      dispatch(clearErrors());
    });
  };

  const onPasswordChange = value => {
    batch(() => {
      dispatch(setPassword(value));
      dispatch(clearErrors());
    });
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (!email || !password) {
      return dispatch(setErrors(['Missing email or password']));
    }

    await dispatch(doLogin());
  };

  return (
    <div className="login-form">
      <form onSubmit={onSubmit}>
        <UserInput type="email">
          <UserInput.Label text="Email" />
          <UserInput.Input value={email} onTextChange={onEmailChange} placeholder="Enter your email address...." />
        </UserInput>
        <UserInput type="password">
          <UserInput.Label text="Password" />
          <UserInput.Input value={password} onTextChange={onPasswordChange} placeholder="Enter your password...." />
        </UserInput>
        <FormButton onPress={onSubmit} status={status}>Login</FormButton>
      </form>
    </div>
  );
};

export default LoginForm;
