import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const createLoginSelector = () => createSelector(
  ({ login }) => login,
  login => login,
);

export const useLogin = () => {
  const selector = useMemo(createLoginSelector, []);
  return useSelector(selector);
};
